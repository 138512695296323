window.$ = require('jquery');

window.jQuery = require('jquery');

/* css blocks*/
/*import 'bootstrap-vue/src/index.scss';*/
import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

import 'izitoast/dist/css/iziToast.min.css';
import './assets/css/vendor/select2.min.css';
import './assets/css/vendor/perfect-scrollbar.css';
import './assets/css/vendor/bootstrap-datepicker3.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './assets/css/dore.light.blue.css';
import './assets/css/main.css';
/* web page css files */
import './assets/css/web.css';
import './assets/icons/boxicons/css/boxicons.min.css';
import './assets/icons/icofont/icofont.min.css';



/* css blocks*/

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '/js/bootstrap.min.css',
        '/css/main.css'
    ]
}


import Vue from 'vue'
import App from './App.vue'
import routes from './router'
import { getRouteByName } from './router'
import Vuex from 'vuex';
import VueMeta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue';
import StoreData from './store/store'
import axios from './plugins/axios';
import Vuelidate from 'vuelidate';
import iziToast from 'izitoast';
import VueRouter from 'vue-router';
import moment from 'moment';
import VueHtmlToPaper from 'vue-html-to-paper';
import AudioRecorder from 'vue-audio-recorder'
import VTooltip from 'v-tooltip'
import JsonExcel from "vue-json-excel";
import Multiselect from 'vue-multiselect';
// components
import FullCalendar from "./plugins/FullCalendar.vue";
import DialogComponent from "./components/popup/dialogbox/DialogComponent.vue";
import LoadingSpinner from "./components/animations/LoadingSpinner.vue";
import Dialog from "./plugins/Dialog.vue";
import DayMonthYearControl from "./components/usercontrols/VmxDayMonthYearControl.vue";
import EffortInput from "./components/usercontrols/VmxEffortControl.vue";
// layouts
import AdminSettingLayout from "./layouts/AdminSettingLayout.vue";
import ContractLayout from "./layouts/ContractLayout.vue";
import Default from "./layouts/Default.vue";
import Login from "./layouts/Login.vue";
import LandingPage from "./layouts/LandingPage.vue";
import PublicLayout from "./layouts/PublicLayout.vue";
import DefaultPage from "./layouts/DefaultPage.vue";
import Service from "./layouts/Service.vue";
import Client from "./layouts/Client.vue";
import VMXServiceSettingLayout from "./layouts/VMXServiceSettingLayout.vue";

export const EventBus = new Vue();

Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(Multiselect);
Vue.use(VueMeta);
Vue.use(VueRouter);
Vue.use(VueHtmlToPaper, options);
Vue.use(AudioRecorder);
Vue.use(VTooltip);

Vue.config.productionTip = false
Vue.component('admin-setting-layout', AdminSettingLayout);
Vue.component('default-layout', Default);
Vue.component('contract-layout', ContractLayout);
Vue.component('login-layout', Login);
Vue.component('landing-layout', LandingPage);
Vue.component('public-layout', PublicLayout);
Vue.component('mainmenu-layout', DefaultPage);
Vue.component('service-layout', Service);
Vue.component('client-layout', Client);
Vue.component('vmx-service-setting-layout', VMXServiceSettingLayout);
Vue.component("downloadExcel", JsonExcel);
Vue.component('Dialog', Dialog);
Vue.component('DialogComponent', DialogComponent);
Vue.component('DeleteComponent', DialogComponent);
Vue.component('fullcalendar', FullCalendar);
Vue.component('LoadingSpinner', LoadingSpinner);
Vue.component('DayMonthYearControl', DayMonthYearControl);
Vue.component('EffortInput', EffortInput);
const store = new Vuex.Store(StoreData);

let router = new VueRouter({
    routes,
    mode: 'history',
});

Vue.mixin({
    methods: {
        getReportPathByName(name) {
            let path = '';
            if(name) {
                path = getRouteByName(name).path;
                let index = path.lastIndexOf('/');
                path = path.substring(1, index);
            }
            return path;
        },
        getPrevUrl() {
            if(this.$store.state.adminSetting.showComponent) {
                // to handle admin setting back button
                this.$store.state.adminSetting.showComponent = false;
            }
            else {
                this.$router.go(-1);
            }
        },
        convertToUTC(dateInput) {
          // Check if dateInput is a string and convert it to a Date object
          const date = new Date(dateInput);

          // Ensure the conversion was successful
          if (isNaN(date.getTime())) {
            throw new Error('Invalid Date');
          }            
          // Ensure the input is a JavaScript Date object
          const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
          ));

          return utcDate.toISOString(); // Returns the date in ISO format
        },
        convertDateToUTCDate(dateInput) {
            // Convert the input date string to a Date object
            const date = new Date(dateInput);
          
            // Adjust the Date object to UTC by setting the time to midnight UTC
            const utcDate = new Date(Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            ));
          
            // Format the UTC date as a string in the format 'YYYY-MM-DD'
            const formattedDate = utcDate.toISOString().split('T')[0];
          
            return formattedDate; // Returns the UTC date in 'YYYY-MM-DD' format
        },                
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        formatDateAlt(value) {
            if (value) {
                return moment(String(value)).format('MMM DD, YYYY');
            }
        },
        formatYear(value) {
            if (value) {
                return moment(String(value)).format('YYYY');
            }
        },
        yearArray () {
            const year = new Date().getFullYear();
            let arrYears = Array.from({length: 30}, (value, index) => (year-25) + index);
            return arrYears.reverse()
        },
        getMonthDay(value) {
            if (value) {
                return moment(String(value)).format('MMM DD, D');
            }
        },
        getTimeValue(value) {
            if (value) {
                value = (new Date(value).toISOString());
                return moment(String(value)).format('HH:mm');
            }
        },
        formatTimeFromDt(value) {
            if (value) {
                return moment(String(value)).format('h:mm a');
            };
        },
        formatTimeFromHM(value) {
            if (value) {
                value = new Date('01/01/1970 ' + value);
                return moment(String(value)).format('h:mm a');
            }
        },
        changeTimeFormat(value) {
            if (value.length == 5) {
                return value + ':00';
            } else return value;
        },
        getDateValue(value) {
            if (value) {
                value = (new Date(value).toISOString());
                return moment(String(value)).format('YYYY-MM-DD');
            }
        },
        getYear(value) {
            if (value) {
                return moment(String(value)).format('YYYY');
            }
        },
        getMonthYear(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM');
            }
        },
        getMonthYearAlt(value) {
            if (value) {
                return moment(String(value)).format('MMM-YYYY');
            }
        },

        formatDateTimeAmPm(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY  LT');
            }
        },
        combineDateAndTime: function(date, time) {
            date = this.getDateValue(date);
            // Check if the time is in AM/PM format
            if (this.isTimeAmPmFormat(time)){
                // Parse the time in AM/PM format and convert to 24-hour format
                time = moment(time, 'hh:mm A').format('HH:mm');                
            }
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        isTimeAmPmFormat: function(timeStr) {
            // Regex to check for time format with AM/PM
            const amPmPattern = /^(1[0-2]|0?[1-9]):([0-5][0-9]) ?(AM|PM)$/i;
            // Check if the string contains "AM" or "PM" (case-insensitive)
            return amPmPattern.test(timeStr);
        },        
        formatDateTimeYearCheck: function(value) {
            if (value) {
                let currentYear = new Date().getFullYear();

                if (currentYear == new Date(value).getFullYear()) {
                    return moment(String(value)).format('DD-MMM  LT');
                } else return moment(String(value)).format('DD-MMM-YYYY  LT');
            }
        },
        formatDateYearCheck: function(value) {
            if (value) {
                let currentYear = new Date().getFullYear();

                if (currentYear == new Date(value).getFullYear()) {
                    return moment(String(value)).format('DD-MMM');
                } else return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        isSameDate(date1, date2) {
            // to check if two dates are same - without considering time
            date1 = new Date(date1);
            date2 = new Date(date2);

            return (date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate()
            );
        },
        getTimeDiff(startDT,endDT, showSeconds) {

            var diffTime = "";
            
            if(endDT == null){
                diffTime = "";
            }
            else{
                const date1 = new Date(startDT);
                const date2 = new Date(endDT);

                const timeDiff = Math.abs(date2 - date1);

                // Calculate the number of hours, minutes, and seconds
                const hours = Math.floor(timeDiff / (1000 * 60 * 60));
                const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

                // Format the result with leading zeros
                const formattedHours = hours.toString().padStart(2, '0');
                const formattedMinutes = minutes.toString().padStart(2, '0');
                const formattedSeconds = seconds.toString().padStart(2, '0');

                diffTime = `${formattedHours}:${formattedMinutes}${showSeconds ? (':' + formattedSeconds) : ''}`;
            }
            return diffTime;

        },     
        effortHoursMinutes(workDay, workHour, workMinute) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },              
        getFirstDateOfWeek(date) {
            var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
            return new Date(date.setDate(diff));
         },
       
        getFirstDateOfMonth(date) {
            date = new Date(date);
            var y = date.getFullYear(), m = date.getMonth();
            var firstDay = new Date();
            firstDay.setFullYear(y, m, 1);
            return this.getDateValue(firstDay);
        },
        getLastDateOfMonth: function(date) {
            date = new Date(date);
            var y = date.getFullYear(), m = date.getMonth();
            var lastDay = new Date();
            lastDay.setFullYear(y, m + 1, 0);
            return this.getDateValue(lastDay);
        },
        getFirstDateOfYear: function(date) {
            var startYear = new Date(date.getFullYear(), 0, 1);
            return startYear;
        },        
        getLastDateFromMonthYear: function(y, m) {
            return new Date(y, m + 1, 0).getDate();
        },
        getDateDifference(startDate, endDate) {
            // return Days, Hours & minutes between two date

            const diff = Math.abs(endDate - startDate);
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));

            return {
                days,
                hours,
                minutes
            };
        },
        convertToDaysAndHours(durationMins) {
            // to convert minutes to days & hours
            if (!durationMins) return;

            const days = Math.floor(durationMins / 1440);
            const hours = Math.floor((durationMins - (days*1440))/60);
            const minutes = Math.round(durationMins % 60);

            if(days > 0) {
                return (days + " days " + hours + " hrs " + minutes + " mins");
            } else {
                return (`${hours ? (hours + " hrs ") : ""}` + `${minutes ? (minutes + " mins") : ""}`);
            }
        },
        convertToDHM(durationMins) {
            // to convert minutes to days, hours & minutes
            if (!durationMins) return;

            const days = Math.floor(durationMins / 1440);
            const hours = Math.floor((durationMins - (days*1440))/60);
            const minutes = Math.round(durationMins % 60);

            if(days > 0) {
                return (days + " days " + hours + " hrs " + minutes + " mins");
            } else {
                return (`${hours ? (hours + " hrs ") : ""}` + `${minutes ? (minutes + " mins") : ""}`);
            }
        },
        convertToHM(durationMins) {
            // to convert minutes to hours & minutes
            if (!durationMins) return;

            const hours = Math.floor((durationMins)/60);
            const minutes = Math.round(durationMins % 60);

            return (`${hours ? (hours + " hrs ") : ""}` + `${minutes ? (minutes + " mins") : ""}`);
        },
        formatDurationDHM(dayValue, hourValue, minuteValue) {
            if (dayValue || hourValue || minuteValue) {
                return `${dayValue ? dayValue+' days' : ''} ${hourValue ? hourValue+' hrs' : ''} ${minuteValue ? minuteValue+' mins' : ''}`;
            }
            else return '';
        },
        formatDurationHM(hourValue, minuteValue) {
            if (hourValue || minuteValue) {
                return `${hourValue ? hourValue+' hrs' : ''} ${minuteValue ? minuteValue+' mins' : ''}`;
            }
            else return '';
        },
        getTimeDuration(date) {
            let today = new Date();
            let updatedDate = new Date(date);

            let diffInMilliSeconds = Math.abs(today - updatedDate) / 1000;

            // calculate days
            const days = Math.floor(diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;

            // calculate hours
            const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
            diffInMilliSeconds -= hours * 3600;

            // calculate minutes
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;

            if (days == 0) {
                if (hours == 0 && minutes == 0) {
                    return `Just Now`;
                } else if (hours == 0 && minutes < 60) {
                    return `${minutes} ${minutes == 1 ? 'minute' : 'minutes'} ago`;
                } else return `${hours} ${hours == 1 ? 'hour' : 'hours'} ago`;
            } else if (days == 1 && (today.getDate() - updatedDate.getDate() == 1)) {
                return `Yesterday`;
            } else if (days < 100) {
                return `${days} ${days == 1 ? 'day' : 'days'} ago`;
            } else return this.formatDateYearCheck(date);
        },
        getFileExtension: function(name) {
            if (name) {
                var re = /(?:\.([^.]+))?$/;
                return re.exec(name)[1];
            }
            return '';
        },
        arrayToString: function(arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },
        stringToArray: function(arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.split(",");
            }
            return [];
        },
        alphaNumericAutoIncrement(array) {
            // AutoIncrement alpha numeric values: from 'TEXT001' to 'TEXT002'
            let result = '';
            if (array.length) {

                array.sort();

                // Get the last element in the array
                let lastElement = array[array.length - 1];
                
                // Extract the numeric part of the last element
                let numberPart = lastElement.match(/\d+$/)[0];
                let prefix = lastElement.slice(0, -numberPart.length);
                
                // Increment the numeric part
                let nextNumber = parseInt(numberPart, 10) + 1;
                
                // Pad the number with leading zeros
                let nextNumberStr = nextNumber.toString().padStart(numberPart.length, '0');
                
                // Combine the prefix and the new numeric part
                result = prefix + nextNumberStr;
            }
            return result;
        },
        getAcronym(keyName) {
            let keywords = [];
            let acronym = "";
            keyName = keyName.replace(/\(|\)/g,'');
            keywords = keyName.split(" ");
            let self = this;
            if (keywords.length == 1) {
                keywords.map(function(keyword) {
                    acronym += keyword.slice(0, 2);
                });
            } else {
                keywords.map(function(keyword) {
                    let newKeyword = acronym + keyword.charAt(0);
                    acronym = newKeyword.slice(0, 2);
                });
            }
            acronym = acronym.toUpperCase();
            return acronym;
        },
        getAcronym(keyName,strlength) {
            let keywords = [];
            let acronym = "";
            keyName = keyName.replace(/\(|\)/g,'');
            keywords = keyName.split(" ");
            let self = this;
            if (keywords.length == 1) {
                keywords.map(function(keyword) {
                    acronym += keyword.slice(0, strlength);
                });
            } else {
                keywords.map(function(keyword) {
                    let newKeyword = acronym + keyword.charAt(0);
                    acronym = newKeyword.slice(0, strlength);
                });
            }
            acronym = acronym.toUpperCase();
            return acronym;
        },
        getAcronymDynamic(keyName,strlength) {
            let keywords = [];
            let acronym = "";
            keyName = keyName.replace(/\(|\)/g,'');
            keywords = keyName.split(" ");
            let self = this;
            if (keywords.length == 1) {
                keywords.map(function(keyword) {
                    acronym += keyword.slice(0, strlength);
                });
            } else {
                keywords.map(function(keyword) {
                    let newKeyword = acronym + keyword.charAt(0);
                    acronym = newKeyword.slice(0, strlength);
                });
            }
            acronym = acronym.toUpperCase();
            return acronym;
        },

        getShortString(value, max) {
            if (max) {
                if (value != null && value.length > max) {
                    return value.substring(0, max) + "...";
                } else
                    return value;
            } else {
                if (value != null && value.length > 20) {
                    return value.substring(0, 25) + "...";
                } else
                    return value;
            }
        },
        getCommaSeperatedString(object) {
            let output = '';
            if (object != null) {
                let length = object.length;
                for (var index = 0; index < length; index++) {
                    output = output + object[index].emailId;
                    if (index != (length - 1)) {
                        output = output + ",";
                    }
                }
            }
            return output;
        },
        formatKey(key) {
            // Convert camelCase or snake_case to readable format
            return key
                .replace(/([a-z])([A-Z])/g, "$1 $2") // camelCase to spaced
                .replace(/_/g, " ")                  // snake_case to spaced
                .replace(/\b\w/g, l => l.toUpperCase()); // capitalize
        },
        formatValue(value) {
            if (value === null || value === undefined) {
                return "Not specified";
            } else if (typeof value === "number" && String(value).length === 13) {
                return this.formatDate(value); // Convert timestamp to readable date if 13-digit number
            } else {
                return value;
            }
        },
        // formatKey(key) {
        //     // Ensure the key is a string before applying replace
        //     if (typeof key === 'string') {
        //       return key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, function(str) {
        //         return str.toUpperCase();
        //       });
        //     }
        //     return key; // If it's not a string, just return it as is
        // },
        // formatValue(value) {
        //     // Format value if needed
        //     return value instanceof Date ? value.toLocaleDateString() : value;
        // },
        getEntityCodeData(code) {
            let output = {};
            code = code.toString();
            if (code && code.length > 0) {
                
                if(code.charAt(0) == 0) 
                    output.accessTo = 'Company';
                else if(code.charAt(0) == 1) 
                    output.accessTo = 'Project';
                else 
                    output.accessTo = 'Both';
                output.moduleId = code.slice(1, 3);
                output.typeId = code.slice(3, 5);
                output.isCustom = code.slice(-1);
            }
            return output;
        },
        getReportUrl(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case "scope_statement_master":
                        output = 'sstrep';
                        break;
                    case "goal_master":
                        output = 'objrep';
                        break;
                    case "user_requirement_master":
                        output = 'urqrep';
                        break;
                    case "system_requirement_spec_master":
                        output = 'srsrep';
                        break;
                    case "uat_master":
                        output = '';
                        break;
                    case "effort_estimation_master":
                        output = '';
                        break;
                    case "process_model_master":
                        output = '';
                        break;
                    case "test_plan_master":
                        output = '';
                        break;
                    case "communication_plan_master":
                        output = '';
                        break;
                    case "resource_plan_master":
                        output = '';
                        break;
                    case "cost_estimation_master":
                        output = '';
                        break;
                    case "budget_plan_master":
                        output = '';
                        break;
                    case "document_folder_master":
                        output = '';
                        break;
                    case "project_plan_master":
                        output = '';
                        break;
                    case "quality_assurance_plan_master":
                        output = '';
                        break;
                    case "development_plan_master":
                        output = '';
                        break;
                    case "risk_plan_master":
                        output = '';
                        break;
                    case "project_team_master":
                        output = '';
                        break;
                    case "design_master":
                        output = '';
                        break;
                    case "financial_txn_master":
                        output = '';
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
        getClientReportUrl(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case "scope_statement_master":
                        output = 'clisstrep';
                        break;
                    case "goal_master":
                        output = 'cliobjrep';
                        break;
                    case "user_requirement_master":
                        output = 'cliurqrep';
                        break;
                    case "system_requirement_spec_master":
                        output = 'clisrsrep';
                        break;
                    case "uat_master":
                        output = '';
                        break;
                    case "effort_estimation_master":
                        output = '';
                        break;
                    case "process_model_master":
                        output = '';
                        break;
                    case "test_plan_master":
                        output = '';
                        break;
                    case "communication_plan_master":
                        output = '';
                        break;
                    case "resource_plan_master":
                        output = '';
                        break;
                    case "cost_estimation_master":
                        output = '';
                        break;
                    case "budget_plan_master":
                        output = '';
                        break;
                    case "meeting_minute_master":
                        output = 'climmnrep';
                        break;
                    case "document_folder_master":
                        output = '';
                        break;
                    case "project_plan_master":
                        output = '';
                        break;
                    case "quality_assurance_plan_master":
                        output = '';
                        break;
                    case "development_plan_master":
                        output = '';
                        break;
                    case "risk_plan_master":
                        output = '';
                        break;
                    case "project_team_master":
                        output = '';
                        break;
                    case "design_master":
                        output = '';
                        break;
                    case "financial_txn_master":
                        output = '';
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
        filterCustomType: function(typeObject, value) {
            return typeObject.filter(function(data, key) {
                if (data.value != value) {
                    return data;
                }
            });
        },
        filterUserType: function(typeObject, value) {
            return typeObject.filter(function(data, key) {
                if (data.value == value) {
                    return data;
                }
            });
        },
        filterUserByTypeCode: function(typeObject, value) {
            return typeObject.filter(function(data, key) {
                if (data.typeCode == value) {
                    return data;
                }
            });
        },
        filterReference: function(object, entityId) {
            return object.filter(function(data, key) {
                if (data.entityId == entityId) {
                    return data;
                }
            });
        },
        effortTypeFilter: function(object, effortType) {
            return object.filter(function(data, key) {
                if (data.id == effortType) {
                    return data;
                }
            });
        },
        getEffortType: function(effortArray, effortTypeId) {
            let result = '';
            if (effortArray.length>0 && effortTypeId) {
                let effortData = effortArray.find((data) => data.id == effortTypeId);
                result = effortData && effortData.name ? effortData.name : '';
            }
            return result;
        },
        setFocus: function() {
            this.$refs.setFocus.focus();
        },
        filterReviewApproval(reviewStatusObject, value) {
            let name = '';
            if (value) {
                name = 'Approved';
            } else {
                name = 'Reviewed';
            }
            return reviewStatusObject.filter(function(data, key) {
                if (data.value == name || data.value == 'Reject') {
                    return data;
                }
            });
        },
        selectBTableRowById(rowId, tableArray, tableRef, primaryColumn) {
            if (this.$refs[tableRef]) {
                // rowId  - row unique id    // tableArray  - btable vmodel    // tableRef  - btable refs    // primaryColumn  - unique field name
                let fieldName = primaryColumn ? primaryColumn : 'id';
                let rowIndex = rowId ? tableArray.findIndex(data => data[fieldName] == rowId) : 0;
                let index = rowIndex == -1 ? 0 : rowIndex;

                this.$refs[tableRef].clearSelected();
                this.$nextTick(() => {
                    this.$refs[tableRef].selectRow(index);
                })
            }
        },
        isItemExists: function(arrayData, itemKey, itemValue) {
            let result = false;
            arrayData.map(data => {
                if (itemValue && data[itemKey]) {
                    if (data[itemKey].toLowerCase() == itemValue.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        activationMailSentToast(emailId) {
            iziToast.success({
                title: 'Check Email',
                message: `Activation email has been sent to "${emailId}"`,
                drag: true,
                icon: 'fa fa-envelope',
                position: 'center',
                titleSize: '15px',
                backgroundColor: 'white',
                progressBar: false,
                titleLineHeight: '40px',
                messageSize: '15px',
                messageLineHeight: '40px',
            });
        },
        dueDateStyling: function(date){
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today) && (date > today)) {        
                    return 'text-dark';
                }
                else if (date < today) {        
                    return 'text-danger';
                }
                else if (date > today) {        
                    return 'text-success';
                }
            }
        },
        taskProgressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                } else if (task.progressPercentage < 50) {
                    return 'warning';
                } else if (task.progressPercentage < 75) {
                    return 'info';
                } else if (task.progressPercentage < 100) {
                    return 'success';
                } else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        dailyAttendanceProgressVariant: function(progressPercentage) {
            if (progressPercentage != null) {
                if (progressPercentage <= 50) {
                    return 'danger';
                } else if (progressPercentage <= 75) {
                    return 'warning';
                } else {
                    return 'success';
                }
            }
        },
        performanceVariant: function(progressPercentage) {
            if (progressPercentage != null) {
                if (progressPercentage <= 70) {
                    return 'danger';
                } else if (progressPercentage < 100) {
                    return 'warning';
                } else if (progressPercentage == 100) {
                    return 'success';
                } else {
                    return 'info';
                }
            }
        },
        overallAttendanceProgressVariant: function(progressPercentage) {
            if (progressPercentage != null) {
                if (progressPercentage <= 70) {
                    return 'danger';
                } else if (progressPercentage <= 90) {
                    return 'warning';
                } else if (progressPercentage <= 110) {
                    return 'success';
                } else {
                    return 'info';
                }
            }
        },

        performanceProgressVariant: function(progressPercentage) {
            if (progressPercentage != null) {
                if (progressPercentage <= 80) {
                    return 'danger';
                } else if (progressPercentage <= 90) {
                    return 'warning';
                } else if (progressPercentage <= 100) {
                    return 'success';
                } else if (progressPercentage <= 130) {
                    return 'info';
                } else {
                    return 'primary';
                }
            }
        },
    }
});

Vue.filter('formatFullDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD MMMM YYYY, dddd');
    }
});
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY');
    }
});
Vue.filter('formatYear', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY');
    }
});
Vue.filter('formatMonthYear', function(value) {
    if (value) {
        return moment(String(value)).format('MMM-YYYY');
    }
});
Vue.filter('formatDateAlt', function(value) {
    if (value) {
        return moment(new Date(value)).format('MMM DD, YYYY');
    }
});
Vue.filter('getMonthDay', function(value) {
    if (value) {
        return moment(String(value)).format('MMM D, ddd');
    }
});
Vue.filter('formatShortMonthDay', function(value) {
    if (value) {
        return moment(String(value)).format('D MMM');
    }
});
Vue.filter('formatDay', function(value) {
    if (value) {
        return moment(String(value)).format('dddd');
    }
});
Vue.filter('formatShortDay', function(value) {
    if (value) {
        return moment(String(value)).format('ddd');
    }
});
Vue.filter('formatTimeFromDt', function(value) {
    if (value) {
        return moment(String(value)).format('h:mm a');
    }
});
Vue.filter('formatTimeFromHM', function(value) {
    if (value) {
        value = new Date('01/01/1970 ' + value);
        return moment(String(value)).format('h:mm a');
    }
});
Vue.filter('formatDuration', function(value) {
    if (value) {
        return moment(String(value), ["hh:mm"]).format('hh:mm');
    }
});
Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY  hh:mm');
    }
});
Vue.filter('formatDateTimeAmPm', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY  LT');
    }
});
Vue.filter('formatDateTimeYearCheck', function(value) {
    if (value) {
        let currentYear = new Date().getFullYear();

        if (currentYear == new Date(value).getFullYear()) {
            return moment(String(value)).format('DD-MMM  LT');
        } else return moment(String(value)).format('DD-MMM-YYYY  LT');
    }
});
Vue.filter('formatDateYearCheck', function(value) {
    if (value) {
        let currentYear = new Date().getFullYear();

        if (currentYear == new Date(value).getFullYear()) {
            return moment(String(value)).format('DD-MMM');
        } else return moment(String(value)).format('DD-MMM-YYYY');
    }
});
Vue.filter('formatShortDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM');
    }
});
Vue.filter('formatShortDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM hh:mm');
    }
});
Vue.filter('formatShortDateTimeAmPm', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM hh:mm a');
    }
});

Vue.filter('formatTimeAmPm', function(value) {
    if (value) {
        return moment(String(value)).format('LT');
    }
});
Vue.filter('formatCustomTime', function(value, type) {
    if (value)
        return moment(String(value)).format(type);
});
Vue.filter('getNameById', function(id, list) {
    let result_name = "";
    list.map(function(value, key) {
        if (value.id == id)
            result_name = value.name;
    });
    return result_name;
});
Vue.filter('getAcronym', function(keyName) {
    let keywords = [];
    let acronym = "";
    if (keyName) {
        keywords = keyName.split(" ");
        let self = this;
        if (keywords.length == 1) {
            keywords.map(function(keyword) {
                acronym += keyword.slice(0, 3);
            });
        } else {
            keywords.map(function(keyword) {
                let newKeyword = acronym + keyword.charAt(0);
                acronym = newKeyword.slice(0, 3);
            });
        }
        acronym = acronym.toUpperCase();
    }
    return acronym;
});
Vue.filter('getAcronymOld', function(keyName) {
    let keywords = [];
    let acronym = "";
    keywords = keyName.split(" ");
    let self = this;
    keywords.map(function(keyword) {
        acronym += keyword.charAt(0);
    });
    acronym = acronym.toUpperCase();
    return acronym;
});
Vue.filter('findIndexOf', function(array, condition) {
    const item = array.find(condition)
    return array.indexOf(item)
});

router.beforeEach((to, from, next) => {
    let publicPage = false;
    if (to.path.includes("registration") || to.path.includes("client/activation") || to.path.includes("team/activation") || to.path.includes("vmx/activation") || to.path.includes("resetpassword") || to.path.includes("emailchange") || to.path.includes("jobview")) {
        publicPage = true;
    }


    const publicPages = [
        '/'
    ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('userAuthData');
    const userinfo = store.getters.currentUser;
    const defaultredirects = store.getters.getDefaultRedirects;
    let defaultURL = '';

    if (authRequired && !loggedIn && !publicPage) {
        localStorage.setItem("pageRedirectURL", window.location.pathname);
        return next('/');
    }
    // if (userinfo.userType == "CAM") {
    //     defaultURL = defaultredirects.cmr;
    // } else {
        defaultURL = defaultredirects.general;
    // }
    if (to.path == publicPages[0] && loggedIn) {
        next(defaultURL);
    }
    next();
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');