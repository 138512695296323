<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" ref="userActivityLogDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" :title="'User Activity Log #' + popupData.userActivityLogId " ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12 px-1">
                                <div v-if="popupData.activity" class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.activity }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity Date</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.startDt | formatDateTimeAmPm}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="popupData.activityLogType && popupData.functionalEntity" class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.activityLogType }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Functionality</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.functionalEntity }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity By</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.activityBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="oldData"  class="col-6 col-sm-6 pr-1">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Old Record</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details"> 
                                                            <div v-for="(value, key) in oldData" :key="key">
                                                                <div v-if="newData[key]!=value || key=='id'">
                                                                    <p class="m-0" :class="{'bg-light-grey' : newData[key]!=value}">
                                                                        <strong>{{ formatKey(key) }}:</strong> {{ formatValue(value) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="newData" class="col-6 col-sm-6 pl-1">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>New Record</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details">
                                                            <div v-for="(value, key) in newData" :key="key">
                                                                <div v-if="oldData[key]!=value || key=='id'">
                                                                    <p class="m-0" :class="{'text-danger' : oldData[key]!=value}">
                                                                        <strong>{{ formatKey(key) }}:</strong> {{ formatValue(value) }}
                                                                    </p>
                                                                </div>
                                                                
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div v-if="oldData && newData" class="col-12 col-sm-12 px-0">
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title mb-2"><span>Record Change</span></p>
                                            <div class="row">
                                                <div class="col-12 col-sm-12 mx-1">
                                                    <vue-json-compare :oldData="oldData" :newData="newData"></vue-json-compare>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div v-if="popupData.location" class="row">
                                    <div class="col-12 col-sm-12">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-4 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Location</label>
                                            </div>
                                            <div class="col-8 col-md-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.location}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.createdDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>     -->                                
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue'; 
import vueJsonCompare from 'vue-json-compare'   
export default {
    props: {
        id: { type: String, default: 'userActivityLogDetailPopup' },    
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object
    },
    components: {
        AttachmentView,
        vueJsonCompare,
    },
    data() {
        return {
            loading: false,
            popupData: {},

            oldData: null,
            newData: null,
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
                if (refEntity === "user_activity_log") {
                    this.$bvModal.show(this.id);
                    axios.get(this.$store.getters.getAPIBasePath + '/useractivitylog/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.userActivityLog;

                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$bvModal.show(this.id);
                    this.loading = false;
                }
                this.$nextTick(() => {

                    console.log("TYP",typeof(this.popupData.oldRecordData));
                    console.log("OLD",this.popupData);

                    this.oldData = this.popupData.oldRecordData ? JSON.parse(this.popupData.oldRecordData) : null;
                    this.newData = this.popupData.newRecordData ? JSON.parse(this.popupData.newRecordData) : null;
                })   

        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>